/**
 * React dependencies
 */
 import { useSelector } from 'react-redux';

/**
 * This is the final view of the app where the
 * user sees whether they won or lost.
 * 
 * @param {Object} props - React object properties 
 */
const Result = () => {
  const { prize, userWon } = useSelector(state => state.api);
  return (
    <section className="Result">
      { userWon ? (
        <div className="Result__win">
          <p className="mb-3 h3"><strong>Christmas has come early!</strong></p>
          <img src='/images/winner.png' alt="Winner badge" width="285" height="72"/>
          <h1 className="mt-3 mb-1">{ prize }</h1>
          <img className="mb-2" src='/images/separator.png' width="151" height="22" alt="Separator"/>
          <p>Head to the bar with your email voucher to claim your cracking Christmas prize.</p>
        </div>
      ) : (
        <div className="Result__loss">
          <h1 className="mb-3 h2">Santa hasn't been...</h1>
          <img className="mb-2" src='/images/out-of-luck.png' alt="Out of luck badge" width="285" height="72"/>
          <p>You're still on our nice list though!</p>
          <img className="my-2" src='/images/separator.png' alt="Separator" width="151" height="22"/>
          <p>Thanks for pulling your Christmas E-cracker and enjoy your night.</p>
        </div>
      )}
    </section>
  );
};

export default Result;