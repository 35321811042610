// Blacks:
export const COLOR_BLACK = '#000000';

// Greens:
export const COLOR_SILVER = '#DEDDDE';

// Greys:
export const COLOR_GREY = '#c1c7d0';
export const COLOR_GREY_DARK = '#5F5F5F';

// Golds:
export const COLOR_GOLD = '#F4C985';
export const COLOR_GOLD_DARK = '#CFA75D';

// Reds:
export const COLOR_RED = '#DD0000';

// Whites:
export const COLOR_WHITE = '#ffffff';